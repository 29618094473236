import Layout from 'components/Layout/Layout'
import { LayoutTextSection } from 'components/Layout/LayoutTextSection'
import Link from 'components/Link/Link'
import SEO from 'components/SEO/SEO'
import { CONTACT_WEBSITE_CUDA_KWIATKI } from 'constants/texts/contact'
import { Icon, IconProps, Text } from 'ui/common'

const SiteCreditsPage: React.FC = () => (
  <Layout>
    <SEO
      title="O autorach strony"
      meta={[{ name: 'robots', content: 'noindex' }]}
    />
    <LayoutTextSection>
      <Text as="h1" mt="xl" mb="m">
        Autorzy strony
      </Text>
      <ul>
        <li>
          <Text pb="xxs">
            <strong>Karolina</strong> - design, zdjęcia produktowe, treść.
          </Text>
          <Text pb="xxs">
            Oprócz prowadzenia wypożyczalni glamo.pl od kilkunastu lat działa w
            branży florystycznej, a od 2016r. przygotowuje{' '}
            <strong>dekoracje kwiatowe na śluby i eventy</strong> - odwiedź jej
            stronę <Link to={CONTACT_WEBSITE_CUDA_KWIATKI}>Cuda Kwiatki</Link> i
            zapoznaj się z kompleksową ofertą dekoracyjną.
          </Text>
          <Text>
            Zapraszamy także na profile społecznościowe firmy Cuda Kwiatki:
          </Text>
          <Text pb="xxs" textAlign="end">
            <strong>
              <IconLink
                to={'https://instagram.com/cudakwiatki/'}
                iconName="Instagram"
              >
                cudakwiatki
              </IconLink>
            </strong>{' '}
            i{' '}
            <strong>
              <IconLink
                to={'https://facebook.com/CudaKwiatki/'}
                iconName="Facebook"
              >
                CudaKwiatki
              </IconLink>
            </strong>
          </Text>
        </li>
        <li>
          <Text>
            <strong>Sławek</strong> - kod i utrzymanie strony.
          </Text>
        </li>
      </ul>

      <Text as="h2" mt="xl" mb="s">
        Zdjęcia
      </Text>
      <Text mb="s">Autorką zdjęć produktowych na stronie jest Karolina.</Text>
      <Text>
        W poszczególnych przypadkach zdjęcia produktów mogą pochodzić z naszych
        poprzednich wypożyczeń i realizacji. Z tego względu chcemy serdecznie
        podziękować fotografom i parom młodym, którzy udostępnili nam zdjęcia
        dekoracji:
      </Text>
      <ul>
        <li>
          <Photographer
            facebook="https://www.facebook.com/BUCHOLCe/"
            instagram="https://www.instagram.com/bucholce/"
          >
            Bartek Bucholc (Bucholce)
          </Photographer>
        </li>
        <li>
          <Photographer
            facebook="https://www.facebook.com/lukaszgoczkowskifotografia"
            instagram="https://www.instagram.com/goczkowski_gorecka_fotografia/"
            website="https://goczkowskigorecka.pl/"
          >
            Gosia Górecka i Łukasz Goczkowski
          </Photographer>
        </li>
        <li>
          <Photographer
            facebook="https://www.facebook.com/kociedanaslubach"
            instagram="https://www.instagram.com/kociedanaslubach/"
            website="https://kocieda-na-slubach.pl/"
          >
            Kocięda na ślubach
          </Photographer>
        </li>
        <li>
          <Photographer
            facebook="https://www.facebook.com/GosiaPaculaFotografia/"
            instagram="https://www.instagram.com/gosiapacula/"
            website="https://pacula.pl/"
          >
            Gosia Pacuła
          </Photographer>
        </li>
        <li>
          <Photographer
            facebook="https://www.facebook.com/annagalertfotografia/"
            instagram="https://www.instagram.com/annagalertfotografia/"
            website="https://annagalert.pl/"
          >
            Anna Galert
          </Photographer>
        </li>
        <li>
          <Photographer facebook="https://www.facebook.com/lukaszfotografuje">
            Łukasz Kłosiński
          </Photographer>
        </li>
        <li>
          <Photographer
            instagram="https://www.instagram.com/atthemoment_fotografiaslubna/"
            website="http://agatkaczykfotografia.pl/"
          >
            Aga Tkaczyk
          </Photographer>
        </li>
        <li>
          <Photographer
            facebook="https://www.facebook.com/pytlikbakfotografia/"
            instagram="https://www.instagram.com/pytlik.bak/"
            website="https://pytlikbak.pl/"
          >
            Pytlik i Bąk
          </Photographer>
        </li>
        <li>
          <Photographer instagram="https://www.instagram.com/senovictorii_fotografia/">
            Wiktoria Kłosińska
          </Photographer>
        </li>
      </ul>
      <Text>
        Zdjęcie na stronie głównej pobrano z{' '}
        <a href="https://www.pexels.com/photo/couple-sitting-on-stairs-3217507/">
          Pexels
        </a>
        , autor:{' '}
        <a href="https://www.pexels.com/@jonathanborba">Jonathan Borba</a>
      </Text>

      <Text as="h2" mt="xl" mb="s">
        Wspieramy otwarte oprogramowanie
      </Text>
      <Text>
        Stronę współtworzą także pośrednio wszyscy twórcy bibliotek,
        oprogramowania, wtyczek, grafik, zdjęć oraz innych elementów
        udostępnionych na darmowych licencjach, a użytych na naszej stronie. W
        szczególności podziękowania należą się:
      </Text>
      <ul>
        <li>
          <Text>
            <strong>
              <a href="https://jakwylaczyccookie.pl/generator-polityki-cookie/">
                jakwylaczyccookie.pl
              </a>
              ,{' '}
              <a href="https://cyberfolks.pl/generator-polityki-prywatnosci/">
                cyberfolks.pl
              </a>
            </strong>{' '}
            - generatory polityki prywatności i polityki plików cookies
          </Text>
        </li>
        <li>
          <Text>
            <strong>
              <a href="https://www.svgrepo.com/">svgrepo.com</a>
            </strong>{' '}
            - zbiór ikonek
          </Text>
        </li>
        <li>
          <Text>
            <strong>
              <a href="https://reactjs.org/">React</a>
            </strong>{' '}
            i{' '}
            <strong>
              <a href="https://www.gatsbyjs.com/">Gatsby</a>
            </strong>{' '}
            - technologie, na których strona została przygotowana
          </Text>
        </li>
        <li>
          <Text>
            <strong>
              <a href="https://www.netlify.com/">Netlify</a>
            </strong>{' '}
            oraz{' '}
            <strong>
              <a href="https://github.com/">GitHub</a>
            </strong>{' '}
            - serwis jest hostowany (technicznie utrzymywany) na serwerach tych
            operatorów
          </Text>
        </li>
      </ul>

      <Text mt="xl">
        Powrót na{` `}
        <Link to="/">stronę główną.</Link>
      </Text>
    </LayoutTextSection>
  </Layout>
)

export default SiteCreditsPage

interface PhotographerProps {
  facebook?: string
  instagram?: string
  website?: string
}

const Photographer: React.FC<PhotographerProps> = ({
  facebook,
  instagram,
  website,
  children,
}) => {
  const linksAvailable = Boolean(facebook || instagram || website)
  return (
    <Text>
      {children}
      {linksAvailable && (
        <>
          {' - '}
          {facebook && <IconLink to={facebook} iconName="Facebook" />}
          {instagram && <IconLink to={instagram} iconName="Instagram" />}
          {website && <IconLink to={website} iconName="Website" />}
        </>
      )}
    </Text>
  )
}

interface IconLinkProps {
  to: string
  iconName: IconProps['name']
}

const IconLink: React.FC<IconLinkProps> = ({ to, iconName, children }) => {
  return (
    <Link to={to} linkType="external" target="_blank">
      <Icon
        name={iconName}
        size={16}
        // @ts-ignore
        style={{ padding: '0 4px' }}
      />
      {children}
    </Link>
  )
}
